import React, {memo} from "react"
import "./style.scss";
import { useInView } from "react-intersection-observer"
import TextReveal from "../../components/TextReveal"
import Img from "gatsby-image"

function VenueSmall(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <article ref={ref} className={["venue-small", inView ? "is-inview" : null].join(' ')}>
      <div className="aspect-ratio">
        { props.image.fluid ?
        <Img fluid={props.image.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          fadeIn={true}
          durationFadeIn={2000}
        />
        : null }
        <div className="logo-positioner">
          { props.logoSmall ?
          <div className="logo" style={{backgroundColor: props.color}}>
            <div className="aspect-ratio">
              <object className="img-contain" aria-label="Small Venue Logo" type="image/svg+xml" data={props.logoSmall.file.url}></object>
            </div>
          </div>
          : null }
          { props.name ?
          <div className="title">
            <TextReveal text={props.name.name} />
          </div>
          : null }
        </div>
        { props.visitLink ?
        <div className="link-positioner animate-in-from-bottom">
          <a href={props.visitLink} target="_blank" rel="noopener noreferrer">Visit<span className="arrow_uprightie"></span></a>
        </div>
        : null }
      </div>
    </article>
  )
}

export default memo(VenueSmall);
