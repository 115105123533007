import React from "react"
import "./style.scss"

import { useInView } from "react-intersection-observer"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function VideoLoop(props) {
  const [ref, inView] = useInView({ triggerOnce: true })
  return (
	<>
		<section ref={ref} className={["video-section-with-content", inView ? "is-inview" : null].join(' ')}>
      <div className="content-box">
        {props.textBoxOne ?
          documentToReactComponents(props.textBoxOne.json)
        : null }
      </div>
      <div className="hero-video-bg animate-in-from-right delay-500ms">
        <div className="aspect-ratio ">
          <video autoPlay muted loop playsInline id="myVideo" className="img-cover">
            <source src={props.video.file.url} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="contrast" style={{backgroundColor: props.contrastBoxColor}}></div>
    </section>
	</>
  )
}

export default VideoLoop
