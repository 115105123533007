import React, {memo, useState} from "react"

import Img from "gatsby-image"

import { useInView } from "react-intersection-observer"
import { Parallax } from "react-scroll-parallax"

import "./style.scss"

function FirstBetSegment(props) {
  const [ref, inView] = useInView({ triggerOnce: true })
  const [muted, setMuted] = useState(true)

  // Art Direction Image source for hero background
  let sources = null
  if(props.imageMobile) {
    sources = [
      props.imageMobile.fluid,
      {
        ...props.image.fluid,
        media: `(min-width: 1024px)`,
      },
    ]
  } else if (props.image) {
    sources = [
      props.image.fluid,
    ]
  }

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    loop: true,
    poster: "",
    muted: muted ? 'muted' : false,
    sources: [
      {
        src: "/DATA/VIDEO/home_hd.mp4",
        type: 'video/mp4',
      },
    ],
  }

  // Header text markup preparation
  let headerTextPrep = ''
  if(props.header) {
    let headerText = props.header.header.split(/\r?\n/)
    headerText.forEach((element, index) => {
      headerTextPrep += "<span class='text--layer'><span class='text--animate delay-" + (index + 2)*250 + "ms'>" + element + "</span></span>"
    })
  }

  return (
    <section id={"first-bet-feature"} ref={ref} className={["first-bet-feature", inView ? "is-inview" : null].join(" ")}>
      <div className="race-lines">
        <div className="race-line red blank"><h3>&nbsp;</h3></div>
        <div className="race-line white blank"><h3>&nbsp;</h3></div>
        <div className="race-line blue blank"><h3>&nbsp;</h3></div>
        <div className="race-line yellow blank"><h3>&nbsp;</h3></div>
        <div className="race-line green blank"><h3>&nbsp;</h3></div>
      </div>
      <div className="race-text">
        <h1><span className="first">1/ST</span><span className="bet">BET</span></h1>
      </div>
      {props.header ? 
        <div className="race-header" dangerouslySetInnerHTML={{__html: '<p>' + headerTextPrep + '</p>'}}></div>
      : null }
      <div className="race-img">
        <Parallax className="custom-class" y={[-20, 20]} opacity={[1, 0]} tagOuter="figure">
          { props.image ?
          <Img
            fluid={sources}
            objectFit="contain"
            objectPosition="50% 50%"
            fadeIn={true}
            durationFadeIn={2000}
          />
          : null }
        </Parallax>
      </div>
      <div className="race-cta">
        <h2 className="h6">
          <a href="https://www.1st.com/bet" target="_blank" rel="noref">
            DOWNLOAD<br/>
            NOW<br/>
            ↓<br/>
            1ST.COM/BET
          </a>
        </h2>
      </div>
    </section>
  )
}

export default memo(FirstBetSegment);
