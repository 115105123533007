import React, {memo} from "react"
import "./style.scss"
import { useInView } from "react-intersection-observer"
import Img from "gatsby-image"
import { Parallax } from "react-scroll-parallax"

function SimpleParallax(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <section ref={ref} className={["simple-parallax", inView ? "is-inview" : null].join(" ")}>
      { props.image ?
      <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
        <Img fluid={props.image.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          fadeIn={true}
          durationFadeIn={2000}
          loading="eager"
        />
      </Parallax>
      : null }
    </section>
  )
}

export default memo(SimpleParallax);
