import React, { useState } from 'react'
import "./style.scss"
import { motion, AnimatePresence } from "framer-motion"

function Tabs (props) {
	const [currentTab, setCurrentTab] = useState(0)

	// Tabs
	const TabPack = props.tabs.map((node, i) => {
		return (
			<button className={`button-tab ${i === currentTab ? 'current' : ''}`} key={i} onClick={() => onTabChange(i)} onKeyDown={() => onTabChange(i)} role="button" tabIndex={0}>
				{node.tab}
			</button>
		)
	})

	// Tabs Content
	const TabContentPack = props.tabs.map((node, i) => {
		if (i === currentTab) {
			return (
				<motion.div 
					key={i}
					initial={{ opacity: 0, y: 10 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: -10 }}
					transition={{ duration: 0.25 }}
				>
					{node.tabContent}
				</motion.div>
			)
		}
		return false
	})

	// On Parent Tab Change
	function onTabChange(i) {
		setCurrentTab(i)
	}

  return props.type === 'li' ? (
		<li>
			<div className={`${props.tabWrapClass} tabs`}>
				{TabPack}
			</div>
			<div className={`${props.tabContentWrapClass} tabs-content`}>
				<AnimatePresence exitBeforeEnter>
					{TabContentPack}
				</AnimatePresence>
			</div>
		</li>
  ) : (
		<>
			<div className={`${props.tabWrapClass} tabs`}>
				{TabPack}
			</div>
			<div className={`${props.tabContentWrapClass} tabs-content`}>
				<AnimatePresence exitBeforeEnter>
					{TabContentPack}
				</AnimatePresence>
			</div>
		</>
	)
}

export default Tabs