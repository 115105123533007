import React from "react"
import "./style.scss"

import { useStaticQuery, graphql } from "gatsby"
// import useQueryString from "../../hooks/useQueryString"
import Pagination from "../Pagination/Pagination"
import Tile from "../Tile/Tile"

// import animationData from "../../DATA/animationData"
// import { motion, AnimatePresence } from "framer-motion"

function Posts(props) {

  const data = useStaticQuery(graphql`
    query PostsQuery {
      allContentfulPost (sort: { fields: [date], order: DESC }) {
        edges {
          node {
            id
            title
            slug
            date
            category {
              title
            }
            excerpt {
              excerpt
            }
            image {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid
              }
            }
            logo {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  const currentPage = props.currentPage
  const postsPerPage = props.postsPerPage //parseInt(props.postsPerPage)
  const skip = props.skip //postsPerPage * (parseInt(currentPage) - 1)
  const skap = skip + postsPerPage
  const maxPages = props.numPages //Math.ceil(data.allContentfulPost.edges.length / postsPerPage)
  const postItems = data.allContentfulPost.edges.slice(skip, skap).map(prepPosts)
  const postSlug = props.slug
	function prepPosts(item) {
		return (
      <Tile key={item.node.id} { ...item } />
		)
	}

  return (
    <section id="posts" className="posts">

      {/* <AnimatePresence exitBeforeEnter>
        <motion.div
          key={"posts-page-" + currentPage}
          initial={animationData.pageAnimation.initial}
          animate={animationData.pageAnimation.active}
          exit={animationData.pageAnimation.exit}
        > */}
          <div className="posts-grid">
            {postItems}
          </div>
        {/* </motion.div>
      </AnimatePresence> */}

      <Pagination currentPage={currentPage} slug={postSlug} maxPages={maxPages} />
    </section>
  )
}

export default Posts