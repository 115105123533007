import React, {memo} from "react"
import { useInView } from "react-intersection-observer"
import TextReveal from "../../components/TextReveal"
import "./style.scss"

function QuoteBox(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <section ref={ref} className={["quotebox", "type-" + props.type, props.theme, inView ? "is-inview" : null].join(" ")}>
		<div className="quote">
			<TextReveal text={props.quote.quote} size="h2" />
			<div className="by"><TextReveal text={"- " + props.by} size="p" /></div>
		</div>
		<div className="quoteby">
			<div className="contrast"></div>
		</div>
	</section>
  );
}

export default memo(QuoteBox)
