import React, {useEffect} from "react"
import { graphql } from "gatsby"
import { ParallaxContext, useController } from "react-scroll-parallax"
import SEO from "../components/seo"

import BetButton from "../components/BetButton/BetButton"
import Hero from "../components/Hero/Hero"
import FirstBetSegment from "../components/FirstBetSegment"
import QuoteBox from "../components/QuoteBox/QuoteBox"
import ContentfulVideoSection from "../components/ContentfulVideoSection/ContentfulVideoSection"
import GQImagePlusContent from "../components/GQImagePlusContent/GQImagePlusContent"
import SimpleParallax from "../components/SimpleParallax/SimpleParallax"
import Tagline from "../components/Tagline/Tagline"
import GQFeaturedSegment from "../components/GQFeaturedSegment/GQFeaturedSegment"
import GQFeaturedSegmentCompact from "../components/GQFeaturedSegmentCompact"
import GQFeaturedShowcase from "../components/GQFeaturedShowcase/GQFeaturedShowcase"
import GQFeaturedShowcaseTabs from "../components/GQFeaturedShowcaseTabs"
import Standard from "../components/Standard/Standard"
import Bet from "../components/Bet/Bet"
import People from "../components/People/People"
import ContentWithTabs from "../components/ContentWithTabs"
import Carousel from "../components/Carousel/Carousel"
import VideoLoop from "../components/VideoLoop/VideoLoop"
import Posts from "../components/Posts/Posts"

const components = {
  ContentfulComponentBetButton: BetButton,
  ContentfulComponentHero: Hero,
  ContentfulComponent1StBetSegment: FirstBetSegment,
  ContentfulComponentQuoteBox: QuoteBox,
  ContentfulComponentVideo: ContentfulVideoSection,
  ContentfulComponentImagePlusContent: GQImagePlusContent,
  ContentfulComponentParallax: SimpleParallax,
  ContentfulComponentTagline: Tagline,
  ContentfulComponentFeaturedSegment: GQFeaturedSegment,
  ContentfulComponentFeaturedSegmentCompact: GQFeaturedSegmentCompact,
  ContentfulComponentFeaturedShowcase: GQFeaturedShowcase,
  ContentfulComponentFeaturedShowcaseTabs: GQFeaturedShowcaseTabs,
  ContentfulComponentStandard: Standard,
  ContentfulComponentBet: Bet,
  ContentfulComponentPeople: People,
  ContentfulComponentContentWithTabs: ContentWithTabs,
  ContentfulComponentCarousel: Carousel,
  ContentfulComponentVideoLoop: VideoLoop,
  ContentfulComponentBlogPosts: Posts,
}

export default ({ data, pageContext }) => {
  const page = data.contentfulPage
  // Fix parallax issues with gatsby scroll to top behavior
  const context = React.useContext(ParallaxContext)
  if (context) {
    const { parallaxController } = useController()
    useEffect(() => {
      window.requestAnimationFrame(() => {
          parallaxController.update()
      })
    })
  }

  return (
    <main className={"main-" + pageContext.slug}>
      <SEO title={pageContext.title ? pageContext.title : "Missing Title"} description={pageContext.description ? pageContext.description.description : "Missing Descritpion"} />
      {page.components?.map((props, i) => {
        let TypeComponent = components[page.components[i].__typename]
        if (TypeComponent === components.ContentfulComponentBlogPosts) {
          return (<TypeComponent key={props.id} { ...props } { ...pageContext } />)
        } else if (TypeComponent) {
            return (<TypeComponent key={props.id} { ...props } />)
        }
        return null
      })}
    </main>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPage( slug: { eq: $slug } ) {
      title
      components {
        __typename
        ... on Node {
          ... on ContentfulComponentBetButton {
            id
            title
          }
          ... on ContentfulComponentHero {
            id
            title
            type
            header {
              header
            }
            image {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            imageMobile {
              fluid (
                maxWidth: 1024,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            slides {
              id
              title
              image {
                fluid (
                  maxWidth: 1920,
                  quality: 80,
                ) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
          ... on ContentfulComponent1StBetSegment {
            id
            title
            type
            header {
              header
            }
            image {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            imageMobile {
              fluid (
                maxWidth: 1024,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          ... on ContentfulComponentQuoteBox {
            id
            title
            by
            quote {
              quote
            }
          }
          ... on ContentfulComponentVideo {
            id
            title
            poster {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                src
              }
            }
            video {
              file {
                url
              }
            }
          }
          ... on ContentfulComponentImagePlusContent {
            id
            title
            type
            headerOne {
              headerOne
            }
            headerTwo {
              headerTwo
            }
            textBoxOne {
              json
            }
            textBoxTwo {
              json
            }
            image {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            imageTwo {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            imageThree {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            color
          }
          ... on ContentfulComponentParallax {
            id
            title
            image {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          ... on ContentfulComponentTagline {
            id
            title
            type
            headerOne {
              headerOne
            }
            textBoxOne {
              json
            }
          }
          ... on ContentfulComponentFeaturedSegment {
            id
            title
            image {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            logoSmall {
              file {
                url
              }
            }
            logoBig {
              file {
                url
              }
            }
            name {
              name
            }
            headline {
              headline
            }
            address {
              address
            }
            location
            aside {
              aside
            }
            description {
              json
            }
            color
            visitLink
            links {
              id
              title
              url
              type
              lightbox
              external
            }
          }
          ... on ContentfulComponentFeaturedSegmentCompact {
            id
            title
            image {
              fluid (
                maxWidth: 2560,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            gallery {
              fluid (
                maxWidth: 1600,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            logoSmall {
              file {
                url
              }
            }
            name {
              name
            }
            headline {
              headline
            }
            address {
              address
            }
            location
            description {
              json
            }
            color
            visitLink
            links {
              id
              title
              url
              type
              lightbox
              external
            }
          }
          ... on ContentfulComponentContentWithTabs {
            id
            title
            classes
            tabs {
              id
              title
              gallery {
                fluid (
                  maxWidth: 1600,
                  quality: 70,
                ) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              description {
                json
              }
              color
            }
          }
          ... on ContentfulComponentFeaturedShowcase {
            id
            title
            header {
              header
            }
            tagline {
              tagline
            }
            featured {
              id
              color
              visitLink
              name {
                name
              }
              image {
                fluid (
                maxWidth: 1600,
                quality: 70,
                ) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              logoSmall {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulComponentFeaturedShowcaseTabs {
            id
            title
            header {
              header
            }
            tagline {
              tagline
            }
            featured {
              id
              color
              visitLink
              location
              links {
                id
                title
                url
                type
                lightbox
                external
              }
              description {
                json
              }
              gallery {
                fluid (
                  maxWidth: 1600,
                  quality: 70,
                ) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              logoSmall {
                file {
                  url
                }
              }
              logoBig {
                file {
                  url
                }
              }
              name {
                name
              }
              image {
                fluid (
                maxWidth: 1600,
                quality: 70,
                ) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              logoSmall {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulComponentStandard {
            id
            title
            header {
              header
            }
            content {
              json
            }
          }
          ... on ContentfulComponentBet {
            id
            title
            header {
              header
            }
            textBoxOne {
              json
            }
            textBoxTwo {
              json
            }
            textBoxThree {
              json
            }
            linkAppStore
            linkPlayStore
            phoneImg {
              fluid (
              maxWidth: 1600,
              quality: 75,
              ) {
                ...GatsbyContentfulFluid
              }
            }
            phoneLayer {
              fluid (
              maxWidth: 1600,
              quality: 75,
              ) {
                ...GatsbyContentfulFluid
              }
            }
            phoneLayerTwo {
              fluid (
              maxWidth: 1600,
              quality: 75,
              ) {
                ...GatsbyContentfulFluid
              }
            }
            phoneLayerThree {
              fluid (
              maxWidth: 1600,
              quality: 75,
              ) {
                ...GatsbyContentfulFluid
              }
            }
            barRed {
              barRed
            }
            barWhite {
              barWhite
            }
            barBlue {
              barBlue
            }
            barYellow {
              barYellow
            }
            barGreen {
              barGreen
            }
          }
          ... on ContentfulComponentPeople {
            id
            title
            classes
            persons {
              id
              name {
                name
              }
              job
              jobMultiline {
                jobMultiline
              }
              location
              email
              phone
              featured
              instagram
              twitter
              facebook
              image {
                fluid (
                maxWidth: 1600,
                quality: 70,
                ) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              bio {
                json
              }
            }
          }
          ... on ContentfulComponentCarousel {
            id
            title
            carousel {
              id
              name
              description {
                description
              }
              visitLink
              logo {
                fluid (
                maxWidth: 1600,
                quality: 70,
                ) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
          ... on ContentfulComponentVideoLoop {
            id
            title
            textBoxOne {
              json
            }
            video {
              file {
                url
              }
            }
            contrastBoxColor
          }
          ... on ContentfulComponentBlogPosts {
            id
            title
            postsPerPage
          }
        }
      }
    }
  }
`