import React, {memo} from "react"
import { useInView } from "react-intersection-observer"
import TextReveal from "../../components/TextReveal"
import "./style.scss"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function Tagline(props) {
  const [ref, inView] = useInView({ triggerOnce: true })
  return (
    <section ref={ref} className={["tagline", "type-" + props.type, inView ? "is-inview" : null].join(' ')}>
		<>	
			{ props.headerOne ? 
		   	<div className="tagline-positioner">
		   		<TextReveal text={props.headerOne.headerOne} size="h2" />
		   	</div>
			: null }
			{ props.textBoxOne ? 
		   	<div className="aside">
			   {documentToReactComponents(props.textBoxOne.json)}
			</div>
			: null }
		   	<div className="decorative-arrow"></div>
	   	</>
	</section>
  );
}

export default memo(Tagline);
