import React, {useState, useEffect} from "react"
import "./style.scss"

import TextReveal from "../TextReveal"

import Img from "gatsby-image"
import Slider from "react-slick"

function Carousel(props) {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)

  let slider1 = React.createRef()
  let slider2 = React.createRef()

  useEffect(() => {
	  setNav1(slider1)
	  setNav2(slider2)
  }, [slider1,slider2])

  const carouselImg = props.carousel.map(prepCarouselImg)
  function prepCarouselImg(item) {
	return (
		<div key={item.id}>
            <div className="carousel-logo">
				<div className="aspect-ratio">
					{item.logo ?
						<Img fluid={item.logo.fluid}
							objectFit="contain"
							objectPosition="50% 50%"
							fadeIn={false}
						/>
					: null }
				</div>
			</div>
		</div>
	)
  }

  const carouselDesc = props.carousel.map(prepCarouselDesc)
  function prepCarouselDesc(item) {
	return (
		<div key={item.id} className="text-box">
			{item.name ? <h3>{item.name}</h3> : null}
			{item.description ? <p>{item.description.description}</p> : null }
		  	<p><a href={item.visitLink} target="_blank" rel="noopener noreferrer"><span className="arrow-right"></span>Visit</a></p>
		</div>
	)
  }

  return (
	<>
        <section className="carousel-section">
          <div className="heading">
            <TextReveal size="h2" text={props.title} />
          </div>
          <div className="carousel logos">
              <Slider
                className="carousel-logos"
                asNavFor={nav1}
                ref={slider => (slider2 = slider)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]}
              >
			  	{carouselImg}
              </Slider>
              <Slider
                className="carousel-desc"
                asNavFor={nav2}
                ref={slider => (slider1 = slider)}
                fade={false}
                arrows={true}
                adaptiveHeight={true}
              >
				{carouselDesc}
              </Slider>
          </div>
        </section>
	</>
  )
}

export default Carousel
