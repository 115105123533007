import React from "react"
import "./style.scss"

import TextReveal from "../TextReveal"
import { useInView } from "react-intersection-observer"
import { Parallax } from "react-scroll-parallax"
import Img from "gatsby-image"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function Bet(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
	<main id="bet">

		<section className="wagering-tool">
		<div className="column one">
		<div className="block">
			<TextReveal size="h2" text={props.header.header} />
			<div className="text uppercase">
			{props.textBoxOne ?
				documentToReactComponents(props.textBoxOne.json)
			: null }
			</div>
			<p className="applinks"><a href={props.linkAppStore} target="_blank" rel="noopener noreferrer" className="button-app"><i className="fab fa-apple"></i><span>APP STORE</span></a><a href={props.linkPlayStore} target="_blank" rel="noopener noreferrer" className="button-app"><i className="fab fa-android"></i><span>1STBET.COM</span></a></p>
		</div>
		<div ref={ref} className={['bet-points', inView ? "is-inview" : null].join(' ')} > 
			<div className="race-lines">
			<div className="race-line red">
				<p>{props.barRed.barRed}</p>
			</div>
			<div className="race-line white">
				<p>{props.barWhite.barWhite}</p>
			</div>
			<div className="race-line blue">
				<p>{props.barBlue.barBlue}</p>
			</div>
			<div className="race-line yellow">
				<p>{props.barYellow.barYellow}</p>
			</div>
			<div className="race-line green">
				<p>{props.barGreen.barGreen}</p>
			</div>
			</div>
		</div>
		</div>

		<div className="column two">
		<div className="block">
			{props.textBoxTwo ?
				documentToReactComponents(props.textBoxTwo.json)
			: null }
		</div>
		<div className="phone">
			<div className="aspect-ratio">
			<Parallax className="phone-layer-1" y={[-10, 10]} tagOuter="figure">
				<Img fluid={props.phoneImg.fluid}
					objectFit="contain"
					objectPosition="50% 50%"
					fadeIn={false}
				/>
			</Parallax>
			<Parallax className="phone-layer-2" x={[2,-2]} y={[-12, 12]} tagOuter="figure">
				<Img fluid={props.phoneLayer.fluid}
					objectFit="contain"
					objectPosition="50% 50%"
					fadeIn={false}
				/>
			</Parallax>
			<Parallax className="phone-layer-3" x={[3,-3]} y={[-14, 14]} scale="" tagOuter="figure">
				<Img fluid={props.phoneLayerTwo.fluid}
					objectFit="contain"
					objectPosition="50% 50%"
					fadeIn={false}
				/>
			</Parallax>
			<Parallax className="phone-layer-4" x={[4,-4]} y={[-16, 16]} tagOuter="figure">
				<Img fluid={props.phoneLayerThree.fluid}
					objectFit="contain"
					objectPosition="50% 50%"
					fadeIn={false}
				/>
			</Parallax>
			</div>
		</div>
		<div className="block underphone">
			{props.textBoxThree ?
				documentToReactComponents(props.textBoxThree.json)
			: null }
		</div>
		</div>
	</section>
	</main>
  )
}

export default Bet
