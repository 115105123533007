import React, {useState} from "react"
import { Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import FsLightbox from "fslightbox-react"

import { Parallax } from "react-scroll-parallax"

import TextReveal from "../../components/TextReveal"
import Tabs from '../Tabs'

import Gallery from '../Gallery'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import "./style.scss"

function GQFeaturedShowcaseTabs(props) {
  const [ref, inView] = useInView({ triggerOnce: true })
  const [toggler, setToggler] = useState(false)

  const tabs = props.featured.map(prepVenuesList)
  function prepVenuesList(item, i) {
    
    // Link Set
    const linkItems = item.links ? item.links.map(prepLinks) : null
    function prepLinks(item) {
      if(item.lightbox) {
        return (
          <div className="link" key={item.id}>
            <a  href={item.url} onClick={
              (e) => {
                e.preventDefault()
                setToggler(!toggler)
              }
            }>
              {item.title}<i className="icon arrow_upright"></i>
            </a>
            <FsLightbox
              toggler={ toggler }
              sources={ [
                item.url,
              ] }
            />
          </div>
        )
      }
      return (
        <a key={item.id} href={item.url} target={item.external ? "_blank" : null} rel="noopener noreferrer">{item.title}<i className="icon arrow_upright"></i></a>
      )
    }

  	return {
      tab: <div key={item.id} className={["animate-in-from-bottom", "delay-" + 125 * (i + 1) + "ms"].join(' ')}>
        <div className="logo-positioner">
          {item.logoSmall.file ?
            <div className="logo">
              <div className="logo-bg" style={{backgroundColor: item.color}}></div>
              <div className="aspect-ratio">
                <img src={item.logoSmall.file.url} className={["img-contain"].join(' ')} alt="Small Logo" />
              </div>
            </div>
          : null }
          <div className="title">
            <TextReveal text={item.name?.name} />
          </div>
          {item.location ?
            <div className="location">
              {item.location}
            </div>
          : null }
        </div>
      </div>,
      tabContent:
			<>
				<div className="property-info">
          {item.description ? documentToReactComponents(item.description.json) : null }
				</div>
				<div className="property-gallery">
          <div key={item.id} className="venue-showcase">
            <Gallery gallery={item.gallery} />
          </div>
				</div>
        {item.visitLink ?
          <div className="description-links">
            <div className="link-positioner">
              {item.visitLink ? 
                <div className="link">
                  <a href={item.visitLink}>
                    Visit {item.name?.name}<i className="icon arrow_upright"></i>
                  </a>
                </div>
              : null }
            </div>
          </div>
        : null}
			</>,
    }
  }

  return (   
    <>     
      <section ref={ref} className={["venues-showcase-tabs", inView ? "is-inview" : null].join(' ')}>

        <div className="header">
          <TextReveal text={props.header?.header}/>
        </div>

        <div className="venues-list grid-properties">
          <Tabs tabs={tabs} tabWrapClass='tab-wrap-class' tabContentWrapClass='tab-content-wrap-class'/>
        </div>

      </section>
    </>
  )
}

export default GQFeaturedShowcaseTabs
