import React, { useState } from "react"
import "./style.scss"

import TextReveal from "../TextReveal"
import Img from "gatsby-image"

import CFBasic from '../Form/CF7/Basic'
import FsLightbox from "fslightbox-react"
import Bio from "../Bio/Bio"


function People(props) {
  const peoplez = props.persons.map(prepPeople)
	
  function prepPeople(item) {
	if(item.featured) {
		return (
			<div key={item.id} className="team-member featured">
				{item.image ?
					<div className="img">
						<div className="aspect-ratio">
							<Img fluid={item.image.fluid}
								objectFit="contain"
								objectPosition="50% 50%"
								fadeIn={true}
							/>
						</div>
					</div>
				: null }
				<div className="description">
					<div className="description-inner-wrap">
						<TextReveal size="h2" text={item.name.name} />
						{item.email && item.phone ?
							<TextReveal size="p" text={item.jobMultiline?.jobMultiline + "<br/><a class='person-email' href='mailto:" + item.email + "'>Email | " + item.email + "</a><br/><a class='person-phone' href='tel:" + item.phone + "'>Phone | " + item.phone + "</a><br/>"} />
						:   <TextReveal size="p" text={item.jobMultiline?.jobMultiline + "<br/>"} />
						}

						<ul className="social-icons social-icons-person c0">
							{item.facebook ?
								<li key={`${item.id}-icon-facebook`} className="social-icon"><a href={item.facebook} target="_blank" rel="noopener noreferrer"><i className={"fab fa-facebook"}></i></a></li>
							: null}
							{item.instagram ?
								<li key={`${item.id}-icon-instagram`} className="social-icon"><a href={item.instagram} target="_blank" rel="noopener noreferrer"><i className={"fab fa-instagram"}></i></a></li>
							: null}
							{item.twitter ?
								<li key={`${item.id}-icon-twitter`} className="social-icon"><a href={item.twitter} target="_blank" rel="noopener noreferrer"><i className={"fab fa-twitter"}></i></a></li>
							: null}
						</ul>
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<div key={item.id} className="team-member featured smol">
				{item.image ?
					<div className="img">
						<div className="aspect-ratio">
							<Img fluid={item.image.fluid}
								objectFit="contain"
								objectPosition="50% 50%"
								fadeIn={true}
							/>
						</div>
					</div>
				: null }
				<div className="description">
					<div className="description-inner-wrap">
						<TextReveal size="h2" text={item.name.name} />
						{item.email && item.phone ?
							<TextReveal size="p" text={item.jobMultiline?.jobMultiline + "<br/><a class='person-email' href='mailto:" + item.email + "'>Email | " + item.email + "</a><br/><a class='person-phone' href='tel:" + item.phone + "'>Phone | " + item.phone + "</a><br/>"} />
						:   <TextReveal size="p" text={item.jobMultiline?.jobMultiline + "<br/>"} />
						}
						
						{item.bio ?
							<Bio bio={item.bio} />
						: null}

						<ul className="social-icons social-icons-person c0">
							{item.facebook ?
								<li key={`${item.id}-icon-facebook`} className="social-icon"><a href={item.facebook} target="_blank" rel="noopener noreferrer"><i className={"fab fa-facebook"}></i></a></li>
							: null}
							{item.instagram ?
								<li key={`${item.id}-icon-instagram`} className="social-icon"><a href={item.instagram} target="_blank" rel="noopener noreferrer"><i className={"fab fa-instagram"}></i></a></li>
							: null}
							{item.twitter ?
								<li key={`${item.id}-icon-twitter`} className="social-icon"><a href={item.twitter} target="_blank" rel="noopener noreferrer"><i className={"fab fa-twitter"}></i></a></li>
							: null}
						</ul>
					</div>
				</div>
			</div>
		)
	}
  }

  return (
	<>
		<section className={`${props.classes} grid-12 team`}>
			{props.classes === 'properties-people' ?
				<>
					<div className="span-12"><h2 className="title">Team</h2></div>
					<div className="properties-contact-form span-4 span-12-tablet padd-2">
						<h4>For more information regarding our properties, please email: <a href="mailto:info@stronachgroup.com">info@stronachgroup.com</a></h4>
						{/* <CFBasic /> */}
					</div>
					<div className="properties-team span-8 span-12-tablet">
						{peoplez}
					</div>
				</>
			:
				<>
					{peoplez}
					<div className="contrast"></div>
				</>
			}
    </section>
	</>
  )
}

export default People
