import React, { useState } from "react"
import FsLightbox from "fslightbox-react"
import "./style.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function Bio(props) {
	const {bio} = props
  const [toggler, setToggler] = useState(false)

  return (
		<>
			<div className="btn" onClick={() => setToggler(!toggler)}>
				<span>View Bio</span>
			</div>
			<FsLightbox
				toggler={toggler}
				sources={[
					<div className="bio-box-wrap">
						<div className="c0 padd-2 flex bio-box">
							<div>
								{documentToReactComponents(bio.json)}
							</div>
						</div>
					</div>,
				]}
			/>
		</>
  )
}

export default Bio
