import React, {memo} from "react"
import "./style.scss"

import { Link } from "gatsby"
import Img from "gatsby-image"

function Tile(props) {
	const post = props.node
	const date = new Date(post?.date)
	
  return (
    <article className="tile">
			{ post.image ?
					<Link to={`/news/${post.slug}/`} className="image">
						<Img
							fluid={post.image.fluid}
							objectFit="cover"
							objectPosition="50% 50%"
							fadeIn={false}
							loading="eager"
						/>
						{ post.logo ?
							<div className="logo-publication c0">
								<div className="logo-publication-inner">
									<Img
										fluid={post.logo.fluid}
										objectFit="contain"
										objectPosition="50% 50%"
										fadeIn={false}
										durationFadeIn={2000}
										loading="eager"
									/>
								</div>
							</div>
						: null }
					</Link>
			: null }
			<div className="description">
				{ post.category ? <h6>{post.category[0].title}</h6> : null }
				{ date ? <h6 style={{marginTop: "-1em"}}>{date.toLocaleDateString(['en-US'], {weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'})}</h6> : null }
				<Link to={`/news/${post.slug}/`} className="post-title"><h2>{post.title}</h2></Link>
				{ post.excerpt ? post.excerpt.excerpt : null }
			</div>
			<div className="button">
				<Link to={`/news/${post.slug}/`} className="btn no-effect"><span>Read More</span></Link>
			</div>
	</article>
  )
}

export default memo(Tile)
