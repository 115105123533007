import React from "react"
import "./style.scss"
import Video from "../Video/Video"

function ContentfulVideoSection(props) {

  const videoJsOptions = {
		autoplay: false,
		controls: true,
		poster: props.poster.fluid.src,
		sources: [
		  {
			  src: props.video.file.url,
			  type: 'video/mp4',
		  },
		],
	}

  return (
    <section className="dss-video">
      { props.title ?
        <div className="details">
          <h2>{props.title}</h2>
        </div>
        : null
      }
      <Video  { ...videoJsOptions } />
    </section>
  )
}

export default ContentfulVideoSection