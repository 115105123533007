import React, {useState} from "react"
import { useInView } from "react-intersection-observer"

import LazyLoad from "react-lazyload"
import Img from "gatsby-image"
import FsLightbox from "fslightbox-react"

import {motion, AnimatePresence} from "framer-motion"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import TextReveal from "../../components/TextReveal"
import animationData from "../../DATA/animationData"

import "./style.scss"

function GQFeaturedSegment(props) {
  const [ref, inView] = useInView({ triggerOnce: true })
  const [refz, inViewz] = useInView({ triggerOnce: true })
  const [toggler, setToggler] = useState(false)

  // Link Set
  const linkItems = props.links ? props.links.map(prepLinks) : null
  function prepLinks(item) {
    if(item.lightbox) {
      return (
        <div className="link" key={item.id}>
          <a  href={item.url} onClick={
            (e) => {
              e.preventDefault()
              setToggler(!toggler)
            }
          }>
            {item.title}<i className="icon arrow_upright"></i>
          </a>
          <FsLightbox
            toggler={ toggler }
            sources={ [
              item.url,
            ] }
          />
        </div>
      )
    }
    return (
      <a key={item.id} href={item.url} target={item.external ? "_blank" : null} rel="noopener noreferrer">{item.title}<i className="icon arrow_upright"></i></a>
    )
  }

  return (
      <article className="featured-segment">
        <div className="aspect-ratio">
          {props.image ?
          <AnimatePresence>
            <LazyLoad offset={500} height={"56vw"}>
              <motion.div
                initial={animationData.fadeInOut.initial}
                animate={animationData.fadeInOut.active}
                exit={animationData.fadeInOut.exit}
              >
                <Img fluid={props.image.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  fadeIn={true}
                  durationFadeIn={2000}
                />
              </motion.div>
            </LazyLoad>
          </AnimatePresence>
          : null }
          <div className="logo-positioner">
            {props.logoSmall ?
              <div ref={ref} className={["logo", inView ? "is-inview" : null].join(' ')}>
                <div className="logo-bg" style={{backgroundColor: props.color}}></div>
                <div className="aspect-ratio">
                  <div className="logo-animation">
                    <object className="img-contain" aria-label="Small Venue Logo" type="image/svg+xml" data={props.logoSmall.file.url}></object>
                  </div>
                </div>
                {props.location ?
                  <div className="location">
                    {props.location}
                  </div>
                : null }
              </div>
            : null }
            {props.name ?
              <div className="title">
                <TextReveal text={props.name.name} />
              </div>
            : null }
          </div>
        </div>
        <div className="featured-segment-description">
          {props.aside ?
            <div className="aside" dangerouslySetInnerHTML={{__html: props.aside.aside}} ></div>
          : null }
          <div className="middle">
            {props.headline ?
              <TextReveal text={props.headline.headline} />
            : null }
            {props.logoBig ?
            <div ref={refz} className={["logo", !props.headline ? "big" : null, inViewz ? "is-inview" : null].join(' ')}>
              <div className="logo-animation">
                <object className="img-contain" aria-label="Venue Logo" type="image/svg+xml" data={props.logoBig ? props.logoBig.file.url : props.logoSmall.file.url}></object>
              </div>
            </div>
            : null }
            {props.address ?
              <div className="address" dangerouslySetInnerHTML={{__html: props.address.address}} ></div>
            : null }
          </div>

          <div className="description">
            <div className="description-text" >
              { props.description ?
                documentToReactComponents(props.description.json)
              : null }
            </div>
            <div className="description-links">
              <div className="link-positioner">
                {props.visitLink ? 
                  <div className="link">
                    <a href={props.visitLink}>
                      Visit {props.name.name}<i className="icon arrow_upright"></i>
                    </a>
                  </div>
                : null }
                {props.links ?
                  linkItems
                : null }
              </div>
            </div>
          </div>

        </div>
      </article>
  )
}

export default GQFeaturedSegment
