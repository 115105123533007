import React, {memo, useState, useEffect} from "react"
import TextReveal from "../../components/TextReveal"
import "./style.scss"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import FsLightbox from 'fslightbox-react'
import {Link} from "gatsby"

const options = {
	renderText: text => {
	  	return text.split('\n').reduce((children, textSegment, index) => {
			return [...children, index > 0 && <br key={index} />, textSegment]
	  	}, [])
	},
}

function Standard(props) {

	const [toggler, setToggler] = useState(false)

  // useEffect(() => {
	// 	if (!document.cookie.split('; ').find(row => row.startsWith('displayOnlyOnce'))) {
	// 		setTimeout(() => {
	// 			setToggler(!toggler)
	// 		}, 3000);
	// 		document.cookie = "displayOnlyOnce=true; expires=Fri, 31 Dec 2020 23:59:59 GMT";
	// 	}
	// },[])

  return (
		<>
			<section className="standard">
				{props.header ?
					<TextReveal size="h2" text={props.header.header} />
				: null }
				{props.content ?
					documentToReactComponents(props.content.json, options)
				: null }
			</section>
			{/* <FsLightbox
				toggler={ toggler }
				customSources={ [
					<section id="newsflash" className="">
						<div className="close-btn" onClick={(toggler) => setToggler(!toggler)}>
							<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path class="fslightbox-svg-path" d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path></svg>
						</div>
						<div className="ds-lightbox mfp-hide c4">
							<div className="ds-lightbox-content">
								<div className="top">
									<div className="logo">
												
										</div>
										<div className="header">
											<h2>Privacy Policy Update</h2>
										</div>
								</div>
								<div className="bottom">
									<h2>As of December 23, 2020, we will be updating our privacy policy. For more information, please see the updated Privacy Policy <Link to="/privacy-policy-2020/">Here</Link></h2>
								</div>
							</div>
						</div>
					</section>
				] }
			/> */}
		</>
  )
}

export default memo(Standard)
