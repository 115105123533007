import React, {memo, useState, useEffect} from "react"
import './style.scss'
import { useInView } from 'react-intersection-observer'
import Typewriter from 'typewriter-effect'
import FsLightbox from 'fslightbox-react'
import {Link} from "gatsby"

function BetButton(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

	const [toggler, setToggler] = useState(false)

  // useEffect(() => {
	// 	if (!document.cookie.split('; ').find(row => row.startsWith('displayOnlyOnce'))) {
	// 		setTimeout(() => {
	// 			setToggler(!toggler)
	// 		}, 3000);
	// 		document.cookie = "displayOnlyOnce=true; expires=Fri, 31 Dec 2020 23:59:59 GMT";
	// 	}
	// },[])
  
  return (
		<>
		{/* <FsLightbox
			toggler={ toggler }
			customSources={ [
				<section id="newsflash" className="">
					<div className="close-btn" onClick={(toggler) => setToggler(!toggler)}>
						<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path class="fslightbox-svg-path" d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path></svg>
					</div>
					<div className="ds-lightbox mfp-hide c4">
						<div className="ds-lightbox-content">
							<div className="top">
								<div className="logo"></div>
								<div className="header">
									<h2>Privacy Policy Update</h2>
								</div>
							</div>
							<div className="bottom">
								<h2>As of December 23, 2020, we will be updating our privacy policy. For more information, please see the updated Privacy Policy <Link to="/privacy-policy-2020/">Here</Link></h2>
							</div>
						</div>
					</div>
				</section>
			] }
		/> */}
	<a id="bet-button" href="https://1st.com/bet/" target="_blank" rel="noopener noreferrer" ref={ref} className={["header", inView ? "is-inview" : null].join(' ')}>

		<div className="bet-button">
			<div className="intro-text">
				<Typewriter
				  onInit={(typewriter) => {
				    typewriter
				    .pauseFor(100)
				    .typeString("The easy and smart way to bet")
				    .start();
				  }}
			   	  options={{
				  	delay: 10,
				  	cursor: ''
				  }}
				/>
			</div>
			<div className="colorful">
				<div className="stripes">
					<div className="stripe-red">&nbsp;</div>
					<div className="stripe-white">&nbsp;</div>
					<div className="stripe-blue">&nbsp;</div>
					<div className="stripe-yellow">

						<Typewriter
						  onInit={(typewriter) => {
						    typewriter
						    .pauseFor(100)
						    .typeString("HANDICAP FOR FREE // BET TO WIN")
						    .start();
						  }}
					   	  options={{
						  	delay: 10,
						  	cursor: ''
						  }}
						/>

					</div>
					<div className="stripe-green">

						<Typewriter
						  onInit={(typewriter) => {
						    typewriter
						    .pauseFor(100)
						    .typeString("SIGN UP TODAY")
						    .start();
						  }}
					   	  options={{
						  	delay: 10,
						  	cursor: ''
						  }}
						/>

					</div>
				</div>
				<div className="box-black">
					<div className="st-bet">

						<Typewriter
						  onInit={(typewriter) => {
						    typewriter
						    .pauseFor(100)
						    .typeString("1/ST")
						    .start();
						  }}
					   	  options={{
						  	delay: 10,
						  	cursor: ''
						  }}
						/>


						<Typewriter
						  onInit={(typewriter) => {
						    typewriter
						    .pauseFor(100)
						    .typeString("Bet")
						    .start();
						  }}
					   	  options={{
						  	delay: 10,
						  	cursor: '.'
						  }}
						/>

					</div>
				</div>
			</div>
  		</div>

	</a>
	</>
  )
}

export default memo(BetButton);
