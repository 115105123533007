import React, {useEffect, useState} from "react"

import Img from "gatsby-image"

import { useInView } from "react-intersection-observer"
import { Parallax, withController } from 'react-scroll-parallax'

import Video from "../../components/Video/Video"

import "./Hero.scss"
import "./HomeHero.scss"

import HeroNew from '../HeroNew'

function Hero(props) {
  const [ref, inView] = useInView({ triggerOnce: true })
  const [muted, setMuted] = useState(true)

	// Take care of parallax offset issues
	useEffect(() => {
		// RAF to update parallax position, it gets lost sometimes otherwise, especially on page changes
		window.requestAnimationFrame(() => {
			props.parallaxController.update()
		})
	},[props.parallaxController])

  // Art Direction Image source for hero background
  let sources = null
  if(props.imageMobile) {
    sources = [
      props.imageMobile.fluid,
      {
        ...props.image.fluid,
        media: `(min-width: 1024px)`,
      },
    ]
  } else if (props.image) {
    sources = [
      props.image.fluid,
    ]
  }

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    loop: true,
    poster: "",
    muted: muted ? 'muted' : false,
    sources: [
      {
        src: "/DATA/VIDEO/home_hd.mp4",
        type: 'video/mp4',
      },
    ],
  }

  const videoJsOptionsNew = {
    autoplay: true,
    controls: false,
    loop: true,
    poster: "",
    muted: muted ? 'muted' : false,
    sources: [
      {
        src: "/DATA/VIDEO/home_hd_new.mp4",
        type: 'video/mp4',
      },
    ],
  }

  // Header text markup preparation
  let headerTextPrep = ''
  if(props.header) {
    let headerText = props.header.header.split(/\r?\n/)
    headerText.forEach((element, index) => {
      headerTextPrep += "<span class='highlight'><span class='text--layer'><span class='text--animate delay-" + index*250 + "ms'>" + element + "</span></span></span>"
    })
  }

  return (
    <>
    <section id={props.type === "Home" ? "home-hero" : props.type === "Home(NEW)" ? "home-hero-new" : "hero"} ref={ref} className={["hero", props.type === "Slim" ? "slim" : null, inView ? "is-inview" : null].join(" ")}>

      { props.type === "Home" ?
      <>
        <div className="race-lines">
          <div className="race-line green blank"><h1>&nbsp;</h1></div>
          <div className="race-line yellow">
            <h1><span className="highlight"><span className="text--layer"><span className="text--animate delay-1250ms">1/ST IS</span></span></span></h1>
          </div>
          <div className="race-line red">
            <h1><span className="highlight"><span className="text--layer"><span className="text--animate delay-1500ms">EARNED</span></span></span></h1>
          </div>
          <div className="race-line blue blank"><h1>&nbsp;</h1></div>
        </div>

        <div className={["hero-video-bg", muted ? "is-muted" : null].join(" ")} role="button" tabIndex={0} onClick={() => setMuted(!muted)} onKeyDown={() => setMuted(!muted)} >
          <div className="video-expander animate-in-from-right delay-1000ms">
            <div className="mute-indicator">
              {
                muted ?
                <div className="muted-on animate-in-from-top"><i className="far fa-volume-slash"></i></div> :
                <div className="muted-off animate-in-from-bottom"><i className="far fa-volume"></i></div>
              }
            </div>
            <div className="aspect-ratio">
              <Video { ...videoJsOptions } mutedState={muted} muteOnClick={true} />
              {/* <video autoPlay muted={muted} loop id="myVideo" className="img-cover">
                <source src="/DATA/VIDEO/home_hd.mp4" type="video/mp4" />
                <track src="#.vtt" kind="captions" srcLang="en" label="english_captions"></track>
              </video> */}
              <div className="decorative-arrow"></div>
            </div>
          </div>
        </div>
      </>
      : props.type === "Home(NEW)" ?
      <HeroNew {...props} />
      :
      <>
        <div className="slider-hero">

          <div className="slide">

            <Parallax className="custom-class" y={[-20, 20]} opacity={[1, 0]} tagOuter="figure">
              { props.image ?
              <Img
                fluid={sources}
                objectFit="cover"
                objectPosition="50% 50%"
                fadeIn={true}
                durationFadeIn={2000}
                loading="eager"
              />
              : null }
            </Parallax>
            
            { headerTextPrep ?
            <div className="hero-wrap">
              <div className="hero-content" dangerouslySetInnerHTML={{__html: '<h1>' + headerTextPrep + '</h1>'}}></div>
            </div>
            : null }

            {props.overlay ? <div className="bg-overlay"></div> : null}

          </div>

        </div>
      </>
      }

    </section>
    </>
  )
}

export default withController(Hero)
