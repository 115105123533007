import React, {memo} from "react"
import { useInView } from "react-intersection-observer"
import Img from "gatsby-image"
import TextReveal from "../../components/TextReveal"
import { Parallax } from "react-scroll-parallax"
import "./style.scss"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function GQImagePlusContent(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  return (
	<>
	    {props.type === '1' ?
    		<section ref={ref} className={["img-plus-content", "type-" + props.type, props.theme, inView ? "is-inview" : null].join(' ')}>
				{props.image ? 
			   	<div className="img animate-in-from-right delay-125ms">
					<Img fluid={props.image.fluid}
						objectFit="cover"
						objectPosition="50% 50%"
						fadeIn={true}
						durationFadeIn={2000}
					/>
				</div>
				: null }
			   	<div className="content">
				   	{ props.textBoxOne ?
					<div className="content-box animate-in-from-bottom delay-250ms">
						{documentToReactComponents(props.textBoxOne.json)}
					</div>
					: null }
			   	</div>
			   	<div className="content underimg">
				   	{ props.headerOne ?
			   		<div className="header">
						<TextReveal text={props.headerOne.headerOne} />
					</div>
					: null }
					{ props.textBoxTwo ?
			   		<div className="content-box2 animate-in-from-bottom delay-500ms">
						{documentToReactComponents(props.textBoxTwo.json)}
					</div>
					: null }
			   	</div>
			
			</section>
		: null }

		{props.type === '2' ?
			<section ref={ref} className={["img-plus-content", "type-" + props.type, props.theme, inView ? "is-inview" : null].join(' ')}>
				<div className="left column">
					<div className="header">
						<TextReveal text={props.headerOne.headerOne} />
					</div>
				   	<div className="content">
					   	{props.headerTwo ?
					   		<TextReveal text={props.headerTwo.headerTwo} />
				   		: null}	
						{ props.textBoxOne ?
						<div className="content-box animate-in-from-bottom delay-250ms">
							{documentToReactComponents(props.textBoxOne.json)}
						</div>
						: null }
				   	</div>
				</div>
				
				<div className="right column">
					{ props.textBoxTwo ?
				   	<div className="content">
					   	<div className="decorative-arrow"></div>
						<div className="content-box animate-in-from-bottom delay-250ms">
							{documentToReactComponents(props.textBoxTwo.json)}
						</div>
				   	</div>
					: null }
					{props.image ? 
				   	<div className="img">
				   		<div className="aspect-ratio">
						   	<Img fluid={props.image.fluid}
								objectFit="cover"
								objectPosition="50% 50%"
								fadeIn={true}
								durationFadeIn={2000}
							/>
				   		</div>
				   	</div>
					: null }
				</div>
			</section>
		: null }

		{props.type === '3' ?
			<section ref={ref} className={["img-plus-content", "type-" + props.type, props.theme, inView ? "is-inview" : null].join(' ')}>
				<div className="row one">
					<div className="header">
						{ props.headerOne ?
						<TextReveal text={props.headerOne.headerOne} />
						: null }
					</div>
				   	<div className="img">
				   		<div className="aspect-ratio">
							<Img fluid={props.image.fluid}
								objectFit="cover"
								objectPosition="50% 50%"
								fadeIn={true}
								durationFadeIn={2000}
							/>
				   		</div>
				   	</div>
					{ props.textBoxOne ?
				   	<div className="content">
						<div className="content-box animate-in-from-bottom delay-250ms">
							{documentToReactComponents(props.textBoxOne.json)}
						</div>
				   	</div>
					: null }
				</div>
				
				<div className="row two">
					{ props.textBoxTwo ?
				   	<div className="content">
						<div className="content-box animate-in-from-bottom delay-250ms">
							{documentToReactComponents(props.textBoxTwo.json)}
						</div>
				   	</div>
					: null }
				</div>
			</section>
		: null }

		{props.type === '4' ?
			<section ref={ref} className={["img-plus-content", "type-" + props.type, props.theme, inView ? "is-inview" : null].join(' ')}>
				<div className="row one">
					{ props.headerOne ?
					<div className="header">
						<TextReveal text={props.headerOne.headerOne} />
					</div>
					: null }
					{ props.textBoxOne ?
				   	<div className="content">
						<div className="content-box animate-in-from-bottom delay-250ms">
							{documentToReactComponents(props.textBoxOne.json)}
						</div>
				   	</div>
					: null }
				</div>
				
				<div className="row two">
					{props.image ? 
				   	<div className="img">
				   		<div className="aspect-ratio">
						   	<Img fluid={props.image.fluid}
								objectFit="cover"
								objectPosition="50% 50%"
								fadeIn={true}
								durationFadeIn={2000}
							/>
				   		</div>
				   	</div>
					: null }
					{ props.textBoxTwo ?
				   	<div className="content">
					   	<div className="decorative-arrow"></div>
						<div className="content-box animate-in-from-bottom delay-250ms">
							{documentToReactComponents(props.textBoxTwo.json)}
						</div>
				   	</div>
					: null }
				</div>
			</section>
		: null }

		{props.type === '5' ?
			<section ref={ref} className={["img-plus-content", "type-" + props.type, props.theme, inView ? "is-inview" : null].join(' ')}>
				<div className="left column">
					{props.headerOne ?
					<div className="header">
						<TextReveal text={props.headerOne.headerOne} />
					</div>
					: null }
				   	<div className="content">
					   	{props.headerTwo ?
					   		<TextReveal text={props.headerTwo.headerTwo} />
				   		: null}
				   		{props.textBoxTwo ?
							<div className="content-box animate-in-from-bottom delay-250ms">
								{documentToReactComponents(props.textBoxTwo.json)}
							</div>
						: null}
				   	</div>
				</div>
				
				<div className="right column">
					{props.image ? 
				   	<div className="img">
				   		<div className="aspect-ratio">
						   	<Img fluid={props.image.fluid}
								objectFit="contain"
								objectPosition="50% 50%"
								fadeIn={true}
								durationFadeIn={2000}
								imgStyle={{objectFit:'contain'}}
							/>
				   		</div>
				   	</div>
					: null }
				   	<div className="content">
					   	{props.textBoxOne ?
						<div className="content-box animate-in-from-bottom delay-250ms">
							{documentToReactComponents(props.textBoxOne.json)}
						</div>
						: null }
				   	</div>
				</div>
			</section>
		: null }


		
		{props.type === '6' ?
			<section ref={ref} className={["img-plus-content", "type-" + props.type, inView ? "is-inview" : null].join(' ')}>

				<div className="color-box" style={{backgroundColor: props.color}}></div>

				<div className="header">
					<TextReveal text={props.headerOne.headerOne} />
				</div>

				<div className="img animate-in-from-left delay-125ms">
		    		<Parallax className="custom-class" y={[-10, 30]} tagOuter="figure">
						<Img fluid={props.image.fluid}
							objectFit="cover"
							objectPosition="50% 50%"
							fadeIn={true}
							durationFadeIn={2000}
						/>
		          	</Parallax>
			   	</div>
			   	<div className="img2 animate-in-from-right delay-375ms">
		    		<Parallax className="custom-class" y={[-30, 30]} tagOuter="figure">
						<Img fluid={props.imageTwo.fluid}
							objectFit="cover"
							objectPosition="50% 50%"
							fadeIn={true}
							durationFadeIn={2000}
						/>
		          	</Parallax>
			   	</div>

				<div className="content">
					<div className="content-box animate-in-from-bottom delay-250ms">
						{documentToReactComponents(props.textBoxOne.json)}
					</div>
				</div>
			</section>
		: null }


		
{props.type === '7' ?
			<section ref={ref} className={["img-plus-content", "type-" + props.type, inView ? "is-inview" : null].join(' ')}>

				<div className="color-box" style={{backgroundColor: props.color}}></div>

				<div className="header">
					<TextReveal text={props.headerOne.headerOne} />
				</div>

				
				<div className="img animate-in-from-left delay-125ms">
	    		<Parallax className="custom-class" y={[-20, 0]} tagOuter="figure">
					<Img fluid={props.image.fluid}
						objectFit="cover"
						objectPosition="50% 50%"
						fadeIn={true}
						durationFadeIn={2000}
					/>
	          	</Parallax>
				</div>
				<div className="img2 animate-in-from-left delay-375ms">
					<Parallax className="custom-class" y={[-20, 10]} tagOuter="figure">
						<Img fluid={props.imageTwo.fluid}
							objectFit="cover"
							objectPosition="50% 50%"
							fadeIn={true}
							durationFadeIn={2000}
						/>
					</Parallax>
				</div>
				<div className="img3 animate-in-from-right delay-500ms">
					<Parallax className="custom-class" y={[-30, 30]} tagOuter="figure">
						<Img fluid={props.imageThree.fluid}
							objectFit="cover"
							objectPosition="50% 50%"
							fadeIn={true}
							durationFadeIn={2000}
						/>
					</Parallax>
				</div>
				
				{props.textBoxOne ?
				<div className="content">
					<div className="content-box animate-in-from-bottom delay-250ms">
						{documentToReactComponents(props.textBoxOne.json)}
					</div>
				</div>
				: null }
			</section>
		: null }


		{props.type === '8' ?
			<section ref={ref} className={["img-plus-content", "type-" + props.type, inView ? "is-inview" : null].join(' ')}>

				<div className="color-box" style={{backgroundColor: props.color}}></div>

				<div className="header">
					<TextReveal text={props.headerOne.headerOne} />
				</div>

				{props.textBoxOne ?
				<div className="content">
					<div className="content-box animate-in-from-bottom delay-250ms">
						{documentToReactComponents(props.textBoxOne.json)}
					</div>
				</div>
				: null }
			</section>
		: null }


		{props.type === 'home_new' ?
			<section ref={ref} className={["img-plus-content", "type-" + props.type, inView ? "is-inview" : null].join(' ')}>

				{props.image ?
					<div className="img animate-in-from-left delay-125ms">
						<Img fluid={props.image.fluid}
							objectFit="cover"
							objectPosition="50% 50%"
							fadeIn={true}
							durationFadeIn={2000}
						/>
						<div className="header">
							<TextReveal text={props.headerOne.headerOne} />
						</div>
					</div>
				: null }

				{props.textBoxOne ?
				<div className="content">
					<div className="content-box animate-in-from-bottom delay-250ms">
						{documentToReactComponents(props.textBoxOne.json)}
					</div>
				</div>
				: null }
			</section>
		: null }
	</>
  )
}

export default memo(GQImagePlusContent);
