import React from 'react'
import Img from 'gatsby-image'

//import PropTypes from 'prop-types'
import './style.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function Gallery(props) {
	const slides = props.gallery
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		fade: false,
		speed: 800,
		pauseOnFocus: true,
		pauseOnHover: true,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		className: 'animated',
		centerMode: false,
	}

	const SlidesPack = slides?.map((slide, i) => {

		const image = slide.fluid

		return (
			<div key={`slide-${i}`} className="slide x1">
				{ image ?
					<div className="aspect-ratio-16-9">
						<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
							loading='lazy'
							fadeIn={true}
						/>
					</div>
				: null }
			</div>
		)
	})

	return (
		<Slider {...settings}>
			{SlidesPack}
		</Slider>
  )
}

export default Gallery