import React from "react"
import "./style.scss"

import Tabs from '../Tabs'
import TextReveal from '../TextReveal'
import Gallery from '../Gallery'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function ContentWithTabs (props) {

  const tabs = props.tabs.map(prepTabs)
  function prepTabs(item, i) {
  	return {
			tab: <h6 class=""><i class="dot"></i> {item.title}</h6>,
			tabContent: <>
				<div className="grid-12">
					<div className="span-8"></div>
					<div className="span-4 span-12-tablet span-12-mobile padd-1-5 text-right">
						<h3>{item.title}</h3>
					</div>
					<div className="span-4 span-12-tablet span-12-mobile">
						{item.description ?
							<div className="description">
								{documentToReactComponents(item.description.json)}
							</div>
						: null }
					</div>
					<div className="span-8 span-12-tablet span-12-mobile">
						<div className="property-gallery">
							<Gallery gallery={item.gallery} />
						</div>
					</div>
					<div className="span-3 span-10-tablet span-10-mobile animate-in-from-left decorative-box" style={{backgroundColor: item.color}}></div>
				</div>
			</>
		}
	}

  return (
    <section className={`grid-12 is-inview ${props.classes}`}>
			<div className="span-9 span-12-tablet span-12-mobile">
				<TextReveal text={'Prime real estate<br/>With racing at its <mark>core</mark>'} size="h2" />
				<TextReveal text={'Overview on redevelopments'} size="h4" />
			</div>
			<Tabs tabs={tabs} tabWrapClass='span-3 span-12-tablet span-12-mobile' tabContentWrapClass='span-12'/>
    </section>
  )
}

export default ContentWithTabs