import React, {useState, useEffect} from 'react'
import { useInView } from 'react-intersection-observer'
import Img from 'gatsby-image'

import TextReveal from '../../components/TextReveal'
import { Transition, SwitchTransition } from 'react-transition-group'
import anime from 'animejs'

import './style.scss'


// Random roll, max is exc, min is inc
function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

function HomeHeroNew(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  // Slider settings
  const [slide, setSlide] = useState(props.slides[0])
  const [slideNr, setSlideNr] = useState(0)

  const slideRate = 4500
  let i = 0
  let max = props.slides.length

  // Slider animation
  useEffect(() => {
    setInterval(() => {
      i++
      if (max === i) {
        i = 0
      }
      setSlide(props.slides[i])
      setSlideNr(i)
    }, slideRate)
  },[])


	// Fade-in with little swing
	const baseDuration = 150
	const baseDurationQuick = 150

	// Animating fade in/out
	const fadeIn = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				translateX: ['-.5em', 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
			})
	}
	const fadeOut = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				translateX: [0,'-.5em'],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)'
			})
	}

  // Blink-in
  const blinkspeed = 10
  const blinkdelay = 40

	// Animating fade in/out quick
	const blinkIn = element => {

    // Randomize appearance
    const appear = getRandomInt(0, 100)
    const reappear = getRandomInt(0, 100)
    const delay = getRandomInt(50, 150)

    // randomizing animation effect
    const randomX1 = getRandomInt(0, 120)
    const randomY1 = getRandomInt(0, 120)
    const randomX2 = getRandomInt(0, 270)
    const randomY2 = getRandomInt(0, 270)
    const randomX2B = getRandomInt(0, 1000)
    const randomY2B = getRandomInt(0, 1000)

    if(appear > 10) {

      anime
        .timeline()
        .add({
          targets: element,
          opacity: [0, 1],
          translateX: randomX1,
          translateY: randomY1,
          duration: blinkspeed,
          delay: delay,
        })
        .add({
          targets: element,
          opacity: [1, 0],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [0, 1],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [1, 0],
          scale: 3,
          translateX: randomX2,
          translateY: randomY2,
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [0, 1],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [1, 0],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [0, 1],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [1, 0],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [1, 0],
          scale: 1,
          translateX: randomX2B,
          translateY: randomY2B,
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [0, 1],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [1, 0],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [0, 1],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: [1, 0],
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          opacity: function() {
            if(reappear > 10){
              return [0, 1]
            }
            return [0, 0]
          },
          duration: blinkspeed,
          delay: blinkdelay,
        })
        .add({
          targets: element,
          translateX: [randomX2,randomX2 - slideRate * 0.02],
          translateY: [randomY2,randomY2 + slideRate * 0.01],
          duration: slideRate,
          easing: 'linear',
        })
    }
	}

  // Animate Slides
  const slideDuration = 1000
	const slideIn = element => {
    setTimeout(() => {
      element.classList.add('inview-rn')
    }, 100)
	}
	const slideOut = element => {
    // setTimeout(() => {
    //   element.classList.add('outgoing-rn')
    // }, 100)
	}

  const image = null

  return (
    <section id='home-hero-new' className="home-hero-new">

      <div className="hs-slides">

        <div className={`hs-slide slide-${slideNr}`}>
            <div className="hs-slide-bg">
              <div className="hs-slide-bg-layer layer-1"></div>
              <SwitchTransition>
                <Transition
                  key={slide.id}
                  timeout={baseDurationQuick}
                  appear={true}
                  onEntering={blinkIn}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="hs-slide-bg-layer layer-2">
                    <div className="shape shape-1">
                      <div className="square">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div>
                      </div>
                      <div className="square">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div>
                      </div>
                      
                      <svg className="svg">
                        <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox"><path d="M1,0.283 L0,0.79 V0.507 L1,0 V0.283 M0,-0.105 H0.476 L0,0.137 V-0.105 M1,0.895 H0.524 L1,0.654 V0.895"></path></clipPath>
                      </svg>
                      <div className="clipped">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div>
                      </div>
                    </div>
                    <div className="shape shape-2">
                      <div className="romb">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div></div>
                      <div className="romb">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div></div>
                      <div className="romb">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div></div>
                    </div>
                  </div>
                </Transition>
              </SwitchTransition>
              <SwitchTransition>
                <Transition
                  key={slide.id}
                  timeout={baseDurationQuick}
                  appear={true}
                  onEntering={blinkIn}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="hs-slide-bg-layer layer-3">
                    <div className="shape shape-3">
                      <div className="chevron">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div></div>
                      <div className="chevron">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div></div>
                    </div>
                    <div className="shape shape-4">
                      <div className="close">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div></div>
                    </div>
                  </div>
                </Transition>
              </SwitchTransition>
              <SwitchTransition>
                <Transition
                  key={slide.id}
                  timeout={baseDurationQuick}
                  appear={true}
                  onEntering={blinkIn}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className="hs-slide-bg-layer layer-4">
                    <div className="shape shape-5">
                      <div className="close">
                        <div className="aspect-ratio">
                          <div className="bg-color"></div>
                        </div></div>
                    </div>
                  </div>
                </Transition>
              </SwitchTransition>
            </div>
          <div className="hs-slide-content">
            <div className="hs-slide-content-row-1">
              <div className="hs-slide-content-logo">
                <TextReveal text={'<mark>1/ST IS</mark>'} size="h1" />
              </div>
              <div className="hs-slide-content-images aspect-ratio">
                <SwitchTransition mode="in-out">
                  <Transition
                    key={slide.id}
                    timeout={slideDuration}
                    appear={true}
                    onEntering={slideIn}
                    onExiting={slideOut}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div className="hs-slide-content-image-wrap">
                      {slide.image.fluid ?
                        <Img
                          fluid={slide?.image.fluid}
                          objectFit="cover"
                          objectPosition="50% 50%"
                          fadeIn={false}
                          loading="eager"
                        />
                      : null }
                    </div>
                  </Transition>
                </SwitchTransition>
              </div>
            </div>
            <div className="hs-slide-content-row-2">
              <div className="swappable">
								<SwitchTransition>
									<Transition
										key={slide.id}
										timeout={baseDurationQuick}
										appear={true}
										onEntering={fadeIn}
										onExiting={fadeOut}
                    mountOnEnter
                    unmountOnExit
									>
                    <TextReveal text={slide.title} size="h2" />
									</Transition>
								</SwitchTransition>
              </div>
            </div>
          </div>
        </div>
      </div>


  	</section>
  )
}

export default HomeHeroNew
