import React from "react"
import { useInView } from "react-intersection-observer"

import { Parallax } from "react-scroll-parallax"

import VenueSmall from "../../components/VenueSmall/VenueSmall"
import Racetrack from "../../components/Racetrack/Racetrack"

import TextReveal from "../../components/TextReveal"

import "./style.scss"

function GQFeaturedShowcase(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  const venueList = props.featured.map(prepVenuesList)
  function prepVenuesList(item, i) {
  	return (
      <li key={item.id} className={["animate-in-from-bottom", "delay-" + 125 * (i + 1) + "ms"].join(' ')}>
        {item.name.name}
      </li>
  	)
  }

  const venueShowcaseList = props.featured.map(prepVenues)
  function prepVenues(item, i) {
  	return (
      <div key={item.id} className="venue-showcase">
        <Parallax className="custom-class" y={i === 0 ? [-20, 40] : i === 1 ? [0,20] : i === 2 ? [0,50] : i === 3 ? [30, 70] : i === 4 ? [30, 60] : [0,0]} tagOuter="figure">
          <VenueSmall { ...item } />
        </Parallax>
      </div>
  	)
  }


  return (        
    <section ref={ref} className={["venues-showcase", inView ? "is-inview" : null].join(' ')}>

      <div className="header">
        <TextReveal text={props.header.header}/>
      </div>

      <ul className="venues-list">
        {venueList}
      </ul>

      <div className="aside animate-in-from-bottom delay-125ms">
        {props.tagline.tagline}
      </div>

      <div className="racetrack-positioner">
        <Racetrack color="#2b2e34" />
      </div>

      <div className="venues-showcase-list">
        {venueShowcaseList}
      </div>

    </section>
  )
}

export default GQFeaturedShowcase
